<template>
  <div
    v-if="product"
    @mouseleave="isShareList = false"
    class="main__product"
  >
    <div class="product__title">
      <p>{{ product.attributes.name }}</p>
      <div
        class="product__title__share"
        @click.stop
      >
        <img
          src="@/assets/img/products/share.svg"
          @mouseenter="isShareList = true"
          alt=""
        />
        <div
          class="product__title__share-list"
          v-if="isShareList"
        >
          <a
            class="product__title__share-list__item"
            :href="
              'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=' + getShareUrl()
            "
            target="_blank"
            ><img src="@/assets/icons/share/ok.svg"
          /></a>
          <a
            class="product__title__share-list__item"
            :href="'https://vk.com/share.php?url=' + getShareUrl()"
            target="_blank"
            ><img src="@/assets/icons/share/vk.svg"
          /></a>
          <a
            class="product__title__share-list__item"
            target="_blank"
            :href="'https://www.facebook.com/sharer/sharer.php?u=' + getShareUrl()"
            ><img src="@/assets/icons/share/fb.svg"
          /></a>
          <a
            class="product__title__share-list__item"
            :href="'https://wa.me/?text=' + getShareUrl()"
            target="_blank"
            ><img src="@/assets/icons/share/whatsapp.svg"
          /></a>
          <a
            class="product__title__share-list__item"
            :href="'https://t.me/share/url?url=' + getShareUrl() + '&text=Поделится с ссылкой'"
            target="_blank"
            ><img src="@/assets/icons/share/telegram.svg"
          /></a>
        </div>
      </div>
    </div>
    <div class="product__content">
      <img
        v-lazy="{ src: imageUrl, loading: imageLoading }"
        class="product__content__pic"
        alt=""
      />
      <div class="product__content__text">
        <div class="product-block__header">
          <div class="product-block__header-left">
            <span class="product-block__stock"> -{{ product.attributes.discount }}% </span>
            <span
              v-if="product.attributes.bonuses"
              class="product-block__bonuses"
            >
              + {{ product.attributes.bonuses }}
              <img
                src="@/assets/img/icons/bonuses-icon.svg"
                alt=""
              />
            </span>
          </div>

          <p>до {{ getDate(product) }}</p>
        </div>
        <p
          v-if="product.attributes.for_members"
          class="product__content__member"
        >
          {{ $t("onlyForClub") }}
        </p>
        <div class="product__content__price">
          <div class="prices">
            <p class="prices__old">{{ product.attributes.old_price }} тг</p>
            <p class="prices__new">{{ product.attributes.new_price }} тг</p>
          </div>
          <p class="prompt">{{ $t("full.discountOnly") }}</p>
        </div>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="product__character__border"
          :style="{ display: getItem(item) ? 'flex' : 'none' }"
        >
          <p>{{ $t(`product.list.${item.type}`) }}</p>
          <p style="text-align: end">
            {{
              typeof product.attributes[item.type] === "boolean"
                ? getYesOrNo(item)
                : product.attributes[item.type]
            }}
            <span v-if="item.type === 'volume'">Л</span>
            <span v-if="item.type === 'strenght'">%</span>
          </p>
        </div>
        <div
          v-if="product.attributes.description"
          class="desc"
        >
          <p class="desc__title">{{ $t(`product.list.description`) }}</p>
          <p class="desc__content">
            {{ product.attributes.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainProduct",
  props: {
    product: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      shareurl: window.location.href,
      isShareList: false,
      list: [
        {
          type: "country",
        },
        {
          type: "regions",
        },
        {
          type: "type",
        },
        {
          type: "aged",
        },
        {
          type: "class",
        },
        {
          type: "package",
        },
        {
          type: "style",
        },
        {
          type: "strenght",
        },
        {
          type: "volume",
        },
        {
          type: "brand",
        },
        {
          type: "non_alco",
        },
        {
          type: "bottle_type",
        },
        {
          type: "gassing",
        },
        {
          type: "color",
        },
        {
          type: "sugar",
        },
        {
          type: "vine_place",
        },
        {
          type: "filtered",
        },
      ],
    }
  },
  mounted() {
    document?.addEventListener("click", () => {
      this.isShareList = false
    })
  },
  computed: {
    imageUrl() {
      if (this.product.attributes?.image?.data) {
        return this.$helpers.getAbsolutePath(this.product.attributes?.image?.data?.attributes?.url)
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
    imageLoading() {
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
  },
  methods: {
    getShareUrl() {
      return window.location.href
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes?.action_end)
      return new Intl.DateTimeFormat("ru-Ru", {
        // year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr)
    },
    getItem(item) {
      if (this.product.attributes[item.type] !== null) {
        return String(this.product.attributes[item.type])
      }
    },
    getYesOrNo(item) {
      return this.product.attributes[item.type] ? "Да" : "Нет"
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
