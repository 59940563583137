<template>
  <div class="about">
    <div class="about__intro">
      <div class="container">
        <div class="text">
          <h1>{{ $t("about.mainTitle") }}</h1>
          <!-- <router-link to="products/PageCatalog">Product</router-link> -->
          <h2>
            {{ $t("about.mainSubtitle") }}
          </h2>
        </div>
        <div class="pic">
          <img
            src="@/assets/img/about/about.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="about__reasons">
      <div class="container">
        <div class="title">
          <hr />
          <p>{{ $t("about.whyTitle") }}</p>
        </div>
        <div class="content">
          <div
            class="about__reasons-content"
            v-for="(reason, index) in $t('about.why')"
            :key="index"
          >
            <img
              class="about__reasons-image"
              :src="require('@/assets/img/about/' + reason.pic)"
            />
            <p class="about__reasons-txt">
              {{ reason.txt }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="about__instr">
      <div class="container">
        <div class="title">
          <p>{{ $t("about.howTitle") }}</p>
        </div>
        <div class="content">
          <div class="about__instr-item">
            <div class="about__instr-content">
              <p class="digits">01</p>
              <p class="about__instr-text">{{ $t("about.howFirst") }}</p>
              <div class="about__instr-images">
                <div class="about__instr-apps">
                  <a
                    href="https://apps.apple.com/kz/app/magnum-club/id1462519012"
                    target="_blank"
                  >
                    <img src="@/assets/img/about/instr/appStore.svg" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=kz.magnum.app"
                    target="_blank"
                  >
                    <img src="@/assets/img/about/instr/googlePlay.svg" />
                  </a>
                  <a
                    href="https://appgallery.huawei.com/#/app/C105705563"
                    target="_blank"
                  >
                    <img src="@/assets/img/about/instr/appGallery.svg" />
                  </a>
                </div>
                <img
                  src="@/assets/img/about/instr/bigPhone.svg"
                  v-if="$isMobile()"
                  class="about__instr-phone"
                />
              </div>
            </div>
            <img
              src="@/assets/img/about/instr/bigPhone.svg"
              v-if="!$isMobile()"
              class="about__instr-phone"
            />
          </div>
          <div class="about__instr-item">
            <div class="about__instr-content">
              <p class="digits">02</p>
              <p class="about__instr-text">{{ $t("about.howSecond") }}</p>
              <div
                class="about__instr-images"
                v-if="$isMobile()"
              >
                <img src="@/assets/img/about/instr/phone1.png" />
                <img src="@/assets/img/about/instr/phone2.png" />
              </div>
            </div>
            <img
              src="@/assets/img/about/instr/phoneLaptop.png"
              v-if="!$isMobile()"
              class="about__instr-bigimg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="about__brands">
      <div class="container">
        <div class="title">
          <p>{{ $t("about.brands") }}</p>
        </div>
        <div class="about__brands__content">
          <img
            v-for="(pic, index) in pics"
            :key="index"
            :src="pic.img"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="about__request">
      <div class="container">
        <button
          class="mobile"
          v-if="!$cookies.get('profile')"
          @click.prevent="openIsAuthActive"
        >
          {{ $t("about.joinClub") }}
        </button>
        <button
          class="mobile"
          v-else
          @click.prevent="isOpen = !isOpen"
        >
          Вы уже зарегистрированы
        </button>
        <div class="about__request__pic">
          <img src="@/assets/img/about/bigDisco.png" />
        </div>
        <div class="about__request__content">
          <p class="title">{{ $t("about.waitTitle") }}</p>
          <p class="request__text">
            {{ $t("about.waitDesc") }}
          </p>
          <button
            class="desk"
            v-if="!$cookies.get('profile')"
            @click="openIsAuthActive"
          >
            {{ $t("about.joinClub") }}
          </button>
          <button
            class="desk"
            v-else
            @click.prevent="isOpen = !isOpen"
          >
            Вы уже зарегистрированы
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal"
      @click.self="isOpen = !isOpen"
      v-if="isOpen"
    >
      <!-- <div class="modal__container"> -->
      <div class="already">
        <h2 class="already__title">Вы уже зарегистрированы</h2>
        <button
          class="already__button"
          @click.prevent="isOpen = false"
        >
          Закрыть
        </button>
      </div>
    </div>
    <!-- </div> -->
    <!-- <div class="warning">
      <div class="warning__content">
        <div class="age">21+</div>
        <p class="warning__content__text" v-html="$t('about.age')"></p>
      </div>
    </div> -->
    <main-product />
  </div>
</template>

<script>
import MainProduct from "../../components/products/MainProduct.vue"
import { mapActions } from "vuex"
import { actionTypes } from "@/store"

export default {
  components: { MainProduct },
  name: "AboutParty",
  methods: {
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
  },
  data() {
    return {
      isOpen: false,
      pics: [
        { img: require("@/assets/img/about/bat.png") },
        { img: require("@/assets/img/about/monkey.png") },
        { img: require("@/assets/img/about/jelzin.png") },
        { img: require("@/assets/img/about/tiger.png") },
        { img: require("@/assets/img/about/carlsberg.png") },
        { img: require("@/assets/img/about/bat.png") },
        { img: require("@/assets/img/about/monkey.png") },
        { img: require("@/assets/img/about/jelzin.png") },
        { img: require("@/assets/img/about/tiger.png") },
        { img: require("@/assets/img/about/carlsberg.png") },
        { img: require("@/assets/img/about/bat.png") },
        { img: require("@/assets/img/about/monkey.png") },
        { img: require("@/assets/img/about/jelzin.png") },
        { img: require("@/assets/img/about/tiger.png") },
        { img: require("@/assets/img/about/carlsberg.png") },
      ],
    }
  },
}
</script>

<style
  lang="scss"
  scoped
>
.recom {
  display: flex;
}
@media screen and (max-width: 768px) {
  .recom {
    flex-direction: column;
  }
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.already {
  background: #fff;
  padding-bottom: 40px;
  max-width: 500px;
  width: 100%;
  height: 200px;
  // display: inline-flex;
  text-align: center;
  border-radius: 20px;
  margin: auto;
  &__title {
    padding: 50px 0 0;
    font-size: 24px;
    margin-bottom: 40px;
    // height: 80%;
    font-family: "CeraPro Bold";
  }
  &__button {
    background-color: #8344d6;
    color: #ffffff;
    text-align: center;
    width: 100%;
    max-width: 200px;
    padding: 14px 0;
    border-radius: 10px;
    // align-self: flex-end  ;
    // margin-top: auto;
    // margin-bottom: 56px;
    // cursor: not-allowed;
  }
}
</style>
